import type { IconProps } from "../types";

export const AcademicCapIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <g stroke="#8B8B8B">
      <path d="M10 11.666 17.5 7.5 10 3.333 2.5 7.5z" />
      <path d="m10 11.667 5.133-2.852a10.07 10.07 0 0 1 .555 5.4A9.96 9.96 0 0 0 10 16.713a9.96 9.96 0 0 0-5.687-2.498 10.07 10.07 0 0 1 .554-5.4z" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 11.666 17.5 7.5 10 3.333 2.5 7.5zm0 0 5.133-2.851c.676 1.715.867 3.582.555 5.399A9.96 9.96 0 0 0 10 16.712a9.96 9.96 0 0 0-5.687-2.498 10.07 10.07 0 0 1 .555-5.4zm-3.333 5v-6.25L10 8.565"
      />
    </g>
  </svg>
);
