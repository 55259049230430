export const FOOTER_PHRASES = {
  COPYRIGHT: "© Copyright 2021 by enkorwithus. All rights reserved.",
};

export const COMPANY_CONTACTS = {
  BUSINESS_REGISTRATION_NUMBER: "562 - 86 - 01724",
  CEO: "Oh Jung Hoon",
  TEL_NUMBER: "070 - 8803 - 1160",
  ADDRESS:
    "601, Twin City Namsan 2, 56, Dongja-dong, Yongsan-gu, Seoul, South Korea",
  // 정보 관리 책임자
};
