import type { ColorType } from "./types";

export const grayScale = {
  black: "#000000",
  100: "#171717",
  200: "#222222",
  300: "#333333",
  main: "#515151",
  450: "#717171",
  500: "#8B8B8B",
  550: "#9C9C9C",
  600: "#A8A8A8",
  650: "#B7B7B7",
  700: "#C1C1C1",
  800: "#DDDDDD",
  900: "#ECECEC",
  1000: "#F6F6F6",
  white: "#FFFFFF",
} as const;

export const yellow = {
  100: "#C28100",
  200: "#EAA700",
  300: "#FFB801",
  main: "#FFD951",
  500: "#FFE998",
  600: "#FFF1C3",
  700: "#FFFDF4",
  opacity: "#FFFgDF4",
} as const;

export const blue = {
  200: "#1A62B8",
  300: "#066EEE",
  main: "#2586F9",
  500: "#74B0F6",
  600: "#E4EEFD",
  700: "#F2FAFF",
  opacity: "#F2FdAFF",
} as const;

export const red = {
  200: "#BF292D",
  300: "#DC161A",
  main: "#F03E41",
  500: "#FB878C",
  600: "#FFD6D7",
  700: "#FFF2F3",
  opacity: "#FFF2fF3",
} as const;

export const orange = {
  200: "#DE5B00",
  300: "#F18409",
  main: "#FFA33C",
  500: "#FFC275",
  600: "#FFDCAF",
  700: "#FFF7EC",
  opacity: "#FFFa7EC",
} as const;

export const green = {
  200: "#009024",
  300: "#1AB62F",
  main: "#4BD05D",
  500: "#93EC9C",
  600: "#C2F1C7",
  700: "#F3FFF4",
  opacity: "#F3FsFF4",
} as const;

export const pink = {
  200: "#b3125f",
  300: "#d91673",
  main: "#ff1988",
  500: "#ff7aba",
  600: "#ffcde5",
  700: "#fff0f7",
  opacity: "rgba(255, 240, 247, 0.5)",
} as const;

/**
 * HEX 색상과 투명도 값을 입력받아 RGBA 형식으로 변환하는 함수
 * @param hexColor - HEX 형식의 색상 코드 (ColorType)
 * @param opacity - 투명도 값 (0.0 ~ 1.0)
 *
 * @returns RGBA 형식의 색상 문자열
 *
 * @example
 *
 * #### color opacity 적용
 * ```typescript
 * const MyText = styled.div`
 *  color: ${getOpacityColor(grayScale.black, 0.2)};
 * `;
 * ```
 */
export function getOpacityColor(hexColor: ColorType, opacity: number): string {
  // HEX 색상에서 R, G, B 값을 추출
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  // RGBA 형식으로 변환
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
