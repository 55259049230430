import styled from "styled-components";

import { grayScale } from "../../colors";
import { ColorType } from "../../colors/types";

const DIVIDER_SIZE = {
  large: 8,
  medium: 1,
} as const;

const DIVIDER_SPACING = {
  widest: 56,
  wide: 32,
  normal: 24,
  narrow: 16,
  slim: 12,
  none: 0,
} as const;

export type DividerSizeType = keyof typeof DIVIDER_SIZE;
export type DividerSpacingType = keyof typeof DIVIDER_SPACING;

export const StyledDivider = styled.hr<{
  size: DividerSizeType;
  spacing: DividerSpacingType | number;
  $bgColor?: ColorType | string;
}>`
  width: 100%;
  height: ${({ size }) => DIVIDER_SIZE[size]}px;
  background-color: ${({ $bgColor }) => $bgColor || grayScale.black};
  border: 0;

  margin: ${({ spacing }) =>
      typeof spacing === "number" ? spacing : DIVIDER_SPACING[spacing]}px
    0;
`;
